

.not-found {
    padding-top: 300px;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    animation: blink 2s infinite;
}

.central-body {
    z-index: 1;
    position: relative;
}

.error-text {
    font-size: 10rem;
    margin: 0;
    animation: blink 9s infinite;
}

.error-message {
    font-size: 2rem;
    margin: 20px 0;
}

.home-link {
    font-size: 1.5rem;
    color: #00c3ff;
    text-decoration: none;
    border: 1px solid #00c3ff;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
}

.home-link:hover {
    background-color: #00c3ff;
    color: black;
}

@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}

.sliding-panel {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 50px; /* Width when closed */
    background: transparent;
    color: white;
    transition: width 0.3s ease-in-out, background-color 0.3s ease-in-out;
    z-index: 1000; /* Ensure it stays above other elements */
}

.sliding-panel.open {
    padding-top: 70px;
    width: 260px; /* Width when open */
    background-color: #333;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
    .sliding-panel {
        width: 40px; /* Smaller width when closed on mobile */
    }
    .sliding-panel.open {
        width: 200px; /* Smaller width when open on mobile */
    }
}

.panel-content {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.panel-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.panel-content li {
    margin: 10px 0;
    cursor: pointer;
}

.panel-toggle {
    position: absolute;
    top: 50%;
    right: -10px; /* Adjust as needed */
    background: linear-gradient(45deg, #000000, #aaaaaa);
    color: white;
    padding: 10px 12px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
    line-height: 1;
    transform: translateY(-50%);
    transition: background-color 0.3s ease-in-out;
}

.panel-toggle:hover {
    background-color: #555;
}
.filter-panel {
    /*position: fixed;*/
    top: 60px;  /* Adjust according to your header height */
    left: 0;
    width: 250px;
    height: 100%;
    background: linear-gradient(45deg, #000000, #aaaaaa);
    color: #fff;
    padding: 20px;
    box-shadow: var(--neon-shadow);;
    transition: transform 0.3s ease;
    z-index: 1000;
}

.filter-panel h2 {
    color: var(--primary-text);
    font-size: 1.5em;
    margin-bottom: 1em;
}

.filter-group {
    margin-bottom: 1em;
}

.filter-group label {
    display: block;
    margin-bottom: 0.5em;
    color: var(--primary-text);
}

.filter-group input[type="range"],
.filter-group select {
    width: 100%;
    padding: 10px;
    background-color: #1b1b1b;
    color: #fff;
    border: none;
    box-shadow: var(--neon-shadow-small);;
    margin-bottom: 5px;
}

.filter-group input[type="checkbox"] {
    margin-right: 10px;
}

.filter-group span {
    color: var(--primary-text);
}

.toggle-button {
    background-color: transparent;
    border: none;
    color: var(--primary-text);
    font-size: 1.5em;
    cursor: pointer;
    position: absolute;
    left: 10px;
}

.tabs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    background-color: #444;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.tab.active {
    background-color: var(--primary-text);
    color: #333;
}
.sort-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sort-button {
    padding: 10px;
    background-color: #444;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.sort-button:hover {
    background-color: #555;
}

.sort-button.active {
    background-color: var(--primary-text);
    color: #333;
    box-shadow: var(--neon-shadow);
}

.reset-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 20px;

    background-color: #ff3366;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.reset-button:hover {
    background-color: #ff6699;
    box-shadow: 0 0 5px #ff3366, 0 0 10px #ff3366, 0 0 15px #ff3366;
}

input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    margin: 10px 0;
    background: transparent;
    align-items: center;

}

input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 15px rgba(0, 2, 2, 0.7), 0 0 20px rgba(223, 234, 232, 0.7);
}


input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 10px rgb(243, 243, 243), 0 0 15px rgb(86, 86, 86);
    transition: box-shadow 0.3s ease;
}

input[type="range"]::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 15px rgba(0, 255, 255, 0.7), 0 0 20px rgba(63, 234, 0, 0.7);
}

input[type="range"]::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
}
.select-options-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.page-title {
    font-size: 24px;
    color: #00e676;
    margin-bottom: 20px;
}

.options-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
}

.form-group {
    display: flex;
    margin-bottom: 25px;
    width: 100%;
}

.form-group label {
    font-size: 18px;
    color: #fff;
    margin-right: 10px;
    width: 150px; /* Fixed width for labels to align properly */
}

.neon-dropdown,
.neon-input {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    background: transparent;
    border: 1px solid #00e676;
    border-radius: 4px;
    outline: none;
}

.neon-dropdown option {
    color: #000;
}

.neon-input::placeholder {
    color: #ccc;
}

.switch-container {
    display: flex;
    flex-direction: column;
    width: 100%; /* Make sure switch container takes full width */
    margin-bottom: 20px;
}

.switch {
    display: flex;
    justify-content: space-between;
}

.switch-label {
    padding: 10px 20px;
    font-size: 16px;
    color: #00e676;
    background: #222;
    border-radius: 4px;
    cursor: pointer;
    flex: 1;
    text-align: center;
}

.switch input[type="radio"] {
    display: none;
}

.switch input[type="radio"]:checked + .switch-label {
    background: #00e676;
    color: #222;
}

.submit-button {
    padding: 10px 20px;
    font-size: 18px;
    color: #222;
    background: #00e676;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
}

.submit-button:hover {
    background: #00c454;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
    .page-title {
        font-size: 20px;
    }

    .form-group {
        flex-direction: column;
        align-items: flex-start;
    }

    .form-group label {
        margin-right: 0;
        margin-bottom: 5px;
        width: 100%;
        font-size: 16px;
    }

    .neon-input,
    .neon-dropdown,
    .submit-button {
        font-size: 14px;
        padding: 8px;
    }

    .switch-container {
        margin-bottom: 15px;
    }

    .switch-label {
        padding: 8px 10px;
        font-size: 14px;
    }

    .submit-button {
        padding: 8px 10px;
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .page-title {
        font-size: 18px;
    }

    .form-group label {
        font-size: 14px;
    }

    .neon-input,
    .neon-dropdown,
    .submit-button {
        font-size: 12px;
        padding: 6px;
    }

    .switch-label {
        padding: 6px 8px;
        font-size: 12px;
    }

    .submit-button {
        padding: 6px 8px;
        font-size: 14px;
    }
}

.chip-offer-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    max-width: 1200px;
    min-width: 400px;
    margin: 0 auto;
}

.chip-category-container {
    background: rgba(0, 0, 0, 1);
    border-radius: 15px;
    padding: 5px;
    position: relative;
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.3);
    transition: all 0.3s ease;
    min-width: 200px;
    max-width: 800px;
}

.chip-category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-left: 40px;
}

.chip-category-title {
    font-size: 20px;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 255, 255, 1);
    margin-left: 100px;
    flex-grow: 1;
}

.toggle-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: #00ffcc;
    border: none;
    color: #000;
    font-size: 20px;
    font-weight: bold;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
}

.toggle-button:hover {
    transform: translateY(-50%) scale(1.1);
    box-shadow: 0 0 15px rgba(0, 255, 255, 0.8);
}

.chip-category-offers {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 10px;
}

.chip-offer-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    max-width: 220px;
    text-align: center;
    color: #fff;
    text-decoration: none;
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
    transition: transform 0.3s;
}

.chip-offer-tile:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(0, 255, 255, 1);
}

.chip-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: rgba(0, 255, 255, 0.2);
    border-radius: 5px;
    padding: 5px 10px;
}

.chip-name {
    font-size: 16px;
    margin: 0;
    flex-grow: 1;
    text-align: left;
}

.chip-pricing {
    font-size: 14px;
    font-weight: bold;
    color: #00ffcc;
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
}

.chip-image {
    width: 100px;
    height: auto;
    margin-bottom: 10px;
}

.chip-details {
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.chip-type, .chip-vram, .chip-cpu, .chip-memory, .chip-disk, .chip-spot {
    margin: 0;
    font-size: 14px;
}

.chip-spot {
    color: #1ee509;
    font-weight: bold;
    text-shadow: 0 0 1px #ff3366, 0 0 15px #ff3366, 0 0 10px #0ab02e;
}

.cheapest-label {
    font-size: 14px;
    color: var(--primary-text);
    padding: 2px 8px;
    margin-right: 10px;
    border-radius: 10px;
    animation: neon 1.5s ease-in-out infinite alternate;
}

.cheapest-label-container{
    padding-left: 40px ;
}

@keyframes neon {
    from {
        box-shadow: var(--neon-shadow);
    }
    to {
        box-shadow: var(--neon-shadow-small);
    }
}
.app-row {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
}
.app-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    max-width: 100px;
    height: 100px; /* Make the tile squared */
    text-align: center;
    color: #fff;
    text-decoration: none;
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
    transition: transform 0.3s;
    cursor: pointer;
    margin: 10px; /* Add margin to create space between tiles */
}

.app-tile-locked {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    max-width: 100px;
    height: 100px; /* Make the tile squared */
    text-align: center;
    text-decoration: none;

    box-shadow: 0 0 10px rgba(241, 151, 15, 0.5);
    transition: transform 0.3s;
    cursor: pointer;
    color: #656464;
    margin: 10px; /* Add margin to create space between tiles */
}

.inactive-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: orange;
    font-size: 0.7rem;
    position: relative;
    width: 100px;
    padding: 1px;
    left: 50%;
    transform: translateX(-50%);
}

.crown-icon {
    margin-right: 5px;
}

.app-tile:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(0, 255, 255, 1);
}

.app-tile-locked:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgb(250, 183, 58);
}

.app-tile-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.app-tile-custom{
    font-size: 60px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.app-tile-content h3 {
    margin: 10px 0 0;
    font-size: 18px;
    color: #fff;
}

.app-tile-content p {
    font-size: 14px;
    color: #ccc;
    margin: 0;
}

@media (max-width: 767px) {
    .chip-category-container {
        padding: 5px;
    }

    .chip-category-title {
        font-size: 18px;
    }

    .toggle-button {
        width: 25px;
        height: 25px;
        font-size: 18px;
    }

    .chip-offer-tile {
        max-width: 100%;
    }

    .cheapest-label {
        font-size: 12px;
        padding: 1px 6px;
    }

    .chip-category-offers {
        gap: 10px;
    }
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9000;
    color: white;
}

.modal-content-common {
    background: linear-gradient(435deg, #333, #144);
    border: 0.1px solid #32a993;
    padding: 20px;
    position: relative;
    width: 400px;
    border-radius: 10px;
}

.modal-price-content {
    background: #111;
    border: 2px solid #00e676;
    padding: 20px;
    position: relative;
    width: 1000px;
    border-radius: 10px;
    box-shadow: 0 0 15px var(--primary-text);
}
.modal-app-content {
    background: #111;
    border: 2px solid #38cba8;
    padding: 20px;
    position: relative;
    width: 600px;
    border-radius: 10px;
    box-shadow: 0 0 15px var(--primary-text);
}


.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    color: var(--primary-text);
    cursor: pointer;
}

.input-container {
    margin-bottom: 15px;
}

.input-container label {
    display: block;
    margin-bottom: 5px;
    color: #00e676;
}

.input-container input {
    width: 100%;
    padding: 10px;
    border: 2px solid #00e676;
    border-radius: 5px;
    background: #222;
    color: #00e676;
    box-shadow: 0 0 10px #00e676;
}

.auth-button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    background: #00e676;
    color: #111;
    cursor: pointer;
    box-shadow: 0 0 10px #00e676;
}

.auth-button:hover {
    background: #00b256;
}

.auth-providers {
    margin-top: 15px;
}

.auth-providers button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: #00e676;
    color: #111;
    cursor: pointer;
    box-shadow: 0 0 10px #00e676;
    margin-bottom: 10px;
}

.auth-providers button:hover {
    background: #00b256;
}

@media (min-width: 375px) {
    .modal-app-content {
        width: 350px;

    }

    .modal-content-common {
        width: 300px;
    }
}

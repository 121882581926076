.landing-page {
    padding: 20px;
    text-align: center;
    /*background: linear-gradient(*/
    /*        to bottom right,*/
    /*        rgba(2, 73, 67, 0.8), !* Base color with some transparency *!*/
    /*        rgba(0, 0, 0, 0.7) !* Black with some transparency *!*/
    /*);*/
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    position: relative;
}

.landing-page h1 {
    margin-bottom: 20px;
    color: #00ff00;
}

.refresh-button {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    opacity: 0;
}

.refresh-button.visible {
    opacity: 1;
}

.refresh-button:hover {
    box-shadow: 0 0 10px 3px rgba(0, 255, 255, 0.7);
}

.refresh-button svg {
    font-size: 1.5em;
}

/* Responsive styles */
.landing-page.mobile {
    padding: 10px;
}

.landing-page.mobile .content {
    width: 100%;
}

.landing-page.tablet .content {
    width: 90%;
}

.refresh-button.mobile {
    width: 40px;
    height: 40px;
    bottom: 10px;
}

.refresh-button.mobile svg {
    font-size: 1.2em;
}
.search-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    top: 20px;
}

.neon-input {
    padding: 0px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 1rem;
    background-color: #1f1f1f;
    box-shadow: var(--neon-shadow-small);
    margin-bottom: 20px;
    width: 60%;
    min-width: 600px;
}

.loading {
    margin-top: 10px;
    color: #fff;
}

.dropdown-results {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    max-width: 500px;
    background-color: #222;
    color: white;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10;
    margin-top: 5px;
    overflow: hidden;
}

.dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    text-decoration: none;
    color: inherit;
    transition: background-color 0.2s ease;
}

.dropdown-item:hover {
    background: linear-gradient(45deg, #000000, #aaaaaa);
}

.dropdown-item-content {
    display: flex;
    align-items: center;
}

.dropdown-image {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.dropdown-name {
    font-weight: bold;
}

.dropdown-name {
    flex: 1;
}

.dropdown-price {
    font-size: 14px;
    font-weight: bold;
    color: #00ffcc;
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
}

@media (max-width: 767px) {
    .neon-input {
        width: 90%;
        min-width: 280px;
    }

    .dropdown-results {
        width: 90%;
    }

    .dropdown-image {
        width: 30px;
        height: 30px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .neon-input {
        width: 80%;
    }
}
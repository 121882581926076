.top-banner {
    width: 100%;
    padding: 1px 0;
    background: linear-gradient(270deg, #000000, #BBBBBB);
    box-shadow: var(--neon-shadow-small);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: transform 0.3s ease-in-out;
    z-index: 10000;
}

.banner-text {
    font-size: 2rem;
    color: var(--primary-text);
    margin: 0;
    padding-left: 10px;
}

.hidden {
    transform: translateY(-100%);
}

.visible {
    transform: translateY(0);
}

.menu-items {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 40px;
    padding-left: 300px;
}

.menu-items-mobile {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 40px;
    padding-left: 200px;
}

.menu-wallet {
    font-size: 2rem;
    color: var(--primary-text);
    text-shadow: var(--neon-shadow);
    margin: 0;
}

.menu-link, .login-link {
    color: var(--primary-text);
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease;
    white-space: nowrap;
}

.menu-link:hover, .login-link:hover {
    color: var(--neon-shadow-small);
}

.login-link {
    cursor: pointer;
}

.menu-icon {
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--primary-text);
    right: 60px;
    top: 35px;
    transform: translateY(-50%);
    position: absolute;
}

.side-panel {
    position: fixed;
    top: 69px; /* Adjust this value based on the height of your banner */
    right: -100%; /* Start hidden off-screen */
    width: 50%;
    height: 30%;
    background-color: #0f0f0f;
    transition: right 0.3s ease-in-out;
    z-index: 1500;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;
    font-size: 20px;
}

.side-panel.open {
    right: 0; /* Slide into view */
}

.isLoggedIn {
    height: 40%;
}

.close-icon {
    cursor: pointer;
    margin-bottom: 2px; /* Space between close icon and menu items */
    color: var(--neon-primary);
}

.side-panel-link {
    margin-bottom: 10px;
    color: white;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease;
}

.side-panel-link:hover {
    text-decoration: underline;
}

.user-dropdown-menu {
    background-color: rgba(0, 0, 0, 0.8); /* Black transparent background */
    color: var(--primary-text); /* Adjust text color if needed */
    min-width: 150px;
    margin: 45px;
}

.user-dropdown-menu .dropdown-item {
    color: var(--primary-text);
}

.user-dropdown-menu .dropdown-item:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Lighten on hover */
}

.user-dropdown {
    background: none;
    border: none;
    color: var(--primary-text);
    font-size: 1rem;
}


.mobile-user-icon {
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--primary-text);
    right: 20px;
    top: 35px;
    transform: translateY(-50%);
    position: absolute;
}

.icon-text {
    display: flex;
    gap: 5px;
}

@media (max-width: 767px) {
    .banner-text{
        font-size: 0.7rem;
        left: 10px;
    }

    .menu-items {
        gap: 10px;
    }

    .menu-link, .login-link {
        font-size: 0.8rem;
    }
}

@media (max-width: 575px) {
    .top-banner {
        padding: 10px 0;
    }

    .banner-text {
        font-size: 1rem;;
    }

    .menu-items {
        gap: 5px;
    }

    .menu-link, .login-link {
        font-size: 0.7rem;
    }
}

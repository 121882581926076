/* Example of global styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background: linear-gradient(45deg, #000000, #aaaaaa);
  color: white;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0;

}

.content {
  padding-top: 70px;
}

.puck-editor ._PuckLayout_1g88c_31 {
  left: 0;
  position: fixed;
  right: 0;
  top: 70px;
  bottom: 10px;
}

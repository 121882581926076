.home-page {
    margin-top: 100px;
}

.hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.hero-content {
    margin-bottom: 20px;
}

.cta-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.cta-buttons input {
    width: 100%;
    max-width: 300px;
    margin-bottom: 10px;
}

.cta-buttons button {
    width: 100%;
    max-width: 300px;
}

.chart-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    height: 400px; /* Adjust as needed for the chart height */
}

.features {
    margin-top: 40px;
}

.feature-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.feature-item {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
    text-align: center;
}

.feature-item .icon {
    font-size: 40px;
    margin-bottom: 10px;
}

@media (max-width: 767px) {
    .cta-buttons {
        flex-direction: column;
        align-items: center;
    }

    .chart-container {
        width: 100%;
        max-width: none;
        height: auto; /* Let the height adjust automatically */
    }

    .feature-item {
        width: 100%;
        margin-bottom: 20px;
    }
}

.gradient-card {
    background: linear-gradient(435deg, #4eb967, #05ecec);
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    min-width: 260px;
}

.gradient-card:hover {
    transform: translateY(-5px);
}


.matrix {
    position: relative;
    width: 100%;
    height: 300px;
    background: black;
    overflow: hidden;
    background: linear-gradient(45deg, #000000, #aaaaaa);
}

.matrix-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    color: #ecf1f3;
    animation: matrixAnimation 5s infinite alternate;
}

@keyframes matrixAnimation {
    0% {
        transform: translate(-50%, -50%) scale(1);
    }
    100% {
        transform: translate(-50%, -50%) scale(1.2);
    }
}

.home-page {
    text-align: center;
    padding: 20px;
}

.banner {
    background: #333;
    color: white;
    padding: 10px;
    font-size: 1.5rem;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 0;
}

.matrix {
    margin: 20px 0;
}

/* BigCard.css */
.big-card {
    background: linear-gradient(435deg, #333, #144);
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    margin: 20px;
    color: white;
}


/* MatrixFalling.css */
@keyframes matrixFall {
    0% {
        transform: translateY(-100%);
        opacity: 1;
    }
    100% {
        transform: translateY(100%);
        opacity: 0;
    }
}

.matrix-falling {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
}

.matrix-falling div {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;  /* Adjust width to fit the larger font size */
    height: 100%;
    color: #000000;
    font-family: 'Courier New', Courier, monospace;
    font-size: 30px; /* Increase font size */
    animation: matrixFall linear infinite;
    opacity: 0;
}

.matrix-falling div::before {
    content: '0 1';
    display: block;
    white-space: nowrap;
    animation: matrixFall linear infinite;
}

/* Add more divs and customize their animation timings */
.matrix-falling div:nth-child(1) { left: 5%; animation-duration: 3s; animation-delay: 0s; }
.matrix-falling div:nth-child(2) { left: 10%; animation-duration: 4s; animation-delay: 1s; }
.matrix-falling div:nth-child(3) { left: 15%; animation-duration: 5s; animation-delay: 2s; }
.matrix-falling div:nth-child(4) { left: 20%; animation-duration: 6s; animation-delay: 3s; }
.matrix-falling div:nth-child(5) { left: 25%; animation-duration: 7s; animation-delay: 4s; }
.matrix-falling div:nth-child(6) { left: 30%; animation-duration: 8s; animation-delay: 5s; }
.matrix-falling div:nth-child(7) { left: 35%; animation-duration: 5s; animation-delay: 1.5s; }
.matrix-falling div:nth-child(8) { left: 40%; animation-duration: 6s; animation-delay: 2.5s; }
.matrix-falling div:nth-child(9) { left: 45%; animation-duration: 7s; animation-delay: 3.5s; }
.matrix-falling div:nth-child(10) { left: 50%; animation-duration: 8s; animation-delay: 4.5s; }
.matrix-falling div:nth-child(11) { left: 55%; animation-duration: 3s; animation-delay: 0.5s; }
.matrix-falling div:nth-child(12) { left: 60%; animation-duration: 4s; animation-delay: 1.5s; }
.matrix-falling div:nth-child(13) { left: 65%; animation-duration: 5s; animation-delay: 2.5s; }
.matrix-falling div:nth-child(14) { left: 70%; animation-duration: 6s; animation-delay: 3.5s; }
.matrix-falling div:nth-child(15) { left: 75%; animation-duration: 7s; animation-delay: 4.5s; }
.matrix-falling div:nth-child(16) { left: 80%; animation-duration: 8s; animation-delay: 5.5s; }
.matrix-falling div:nth-child(17) { left: 85%; animation-duration: 5s; animation-delay: 2s; }
.matrix-falling div:nth-child(18) { left: 90%; animation-duration: 6s; animation-delay: 3s; }
.matrix-falling div:nth-child(19) { left: 95%; animation-duration: 7s; animation-delay: 4s; }


/* theme.css */

:root {
    --neon-primary: #eff6f5;
    --neon-shadow: 0 0 10px var(--neon-primary), 0 0 10px var(--neon-primary), 0 0 10px var(--neon-primary);
    --neon-shadow-small: 0 0 10px var(--neon-primary), 0 0 5px var(--neon-primary), 0 0 5px var(--neon-primary);
    --primary-text: #ffffff;
    --background: linear-gradient(
            to bottom right,
            rgba(4, 230, 184, 0.8), /* Base color with some transparency */
            rgba(0, 0, 0, 0.7) /* Black with some transparency */
    ) !important;
}

body {
    color: var(--primary-text);
}

/* Flexbox grid system for responsiveness */
.col-xs-1 { flex-basis: 8.33%; }
.col-xs-2 { flex-basis: 16.66%; }
.col-xs-3 { flex-basis: 25%; }
.col-xs-4 { flex-basis: 33.33%; }
.col-xs-5 { flex-basis: 41.66%; }
.col-xs-6 { flex-basis: 50%; }
.col-xs-7 { flex-basis: 58.33%; }
.col-xs-8 { flex-basis: 66.66%; }
.col-xs-9 { flex-basis: 75%; }
.col-xs-10 { flex-basis: 83.33%; }
.col-xs-11 { flex-basis: 91.66%; }
.col-xs-12 { flex-basis: 100%; }

.row {
    display: flex;
    flex-wrap: wrap;
}

.col {
    padding: 15px;
}

/* Example media queries for responsiveness */
@media (max-width: 768px) {
    .col-xs-6 { flex-basis: 100%; }
    .col-xs-12 { flex-basis: 100%; }
}

@media (max-width: 480px) {
    .col-xs-6 { flex-basis: 100%; }
    .col-xs-12 { flex-basis: 100%; }
}

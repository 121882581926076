.wallet-container {
    position: absolute;
    right: 40px;
    top: 20px;
    cursor: pointer;
}

.wallet-amount {
    color: var(--primary-text);
    transition: transform 0.2s ease-in-out;
}

.wallet-amount:hover {
    transform: scale(1.5);
}

.small-screen .wallet-amount {
    font-size: 20px;
}

.medium-screen .wallet-amount {
    font-size: 20px;
    padding-left: 40px;

}

.large-screen .wallet-amount {
    font-size: 30px;
    padding-left: 50px;

}

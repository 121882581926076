.selected-offer-container {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    padding: 20px;
    max-width: 800px;
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.3);
    color: #fff;
    margin: 110px auto;
}

/* Mobile styles */
@media (max-width: 600px) {
    .selected-offer-container {
        padding: 15px;
        margin: 110px auto;

    }

    .selected-offer-title {
        font-size: 20px;
    }

    .selected-offer-price {
        font-size: 18px;
    }

    .app-name-input {
        font-size: 14px;
        padding: 8px;
    }

    .llm-tag {
        font-size: 12px;
        padding: 4px 10px;
    }

    .deploy-button {
        font-size: 16px;
        padding: 8px;
    }
}

.selected-offer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.selected-offer-title {
    font-size: 24px;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 255, 255, 1);
}

.selected-offer-price {
    font-size: 20px;
    color: #00ffcc;
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
}

.selected-offer-details {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.selected-offer-detail {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
}

.app-name-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
}

.llm-tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.llm-tag {
    background: rgba(0, 255, 255, 0.2);
    border-radius: 20px;
    padding: 5px 15px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.llm-tag:hover {
    background: rgba(0, 255, 255, 0.4);
    transform: scale(1.05);
}

.llm-tag.selected {
    background: #00ffcc;
    color: #000;
}

.deploy-button {
    width: 100%;
    padding: 10px;
    background: #00ffcc;
    border: none;
    border-radius: 5px;
    color: #000;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.deploy-button:hover {
    background: #00e6b8;
    box-shadow: 0 0 15px rgba(0, 255, 255, 0.8);
}

.pricing-modal {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}

.subscription-card {
    position: relative;
    margin: 10px;
    border-radius: 10px;
    overflow: hidden;
    background: linear-gradient(
            to bottom right,
            rgba(4, 230, 184, 0.8), /* Base color with some transparency */
            rgba(0, 0, 0, 0.7) /* Black with some transparency */
    );
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Black shadow */
    transition: transform 0.3s, box-shadow 0.3s;
    height: 300px;
    width: 310px;
}

/* Ensure content is on top of the background */
.subscription-content {
    z-index: 1;
}

.subscription-card:hover {
    transform: translateY(-5px);
    box-shadow: var(--neon-shadow);
}

.subscription-header {
    display: flex;
    flex-direction: row; /* Align items in a row */
    align-items: center; /* Align items vertically */
    justify-content: space-between; /* Space items evenly */
    margin-bottom: 20px;
}

.subscription-card h3 {
    margin: 0;
    flex: 1; /* Allow heading to grow and take available space */
    font-size: 24px;
}

.subscription-card ul {
    list-style-type: none;
    padding: 0;
    text-align: left; /* Align text to the left */
}

.subscription-card ul li {
    margin-bottom: 5px;
    position: relative;
    padding-left: 30px; /* Space for the checkmark */
}

.subscription-card ul li::before {
    content: '✔'; /* Unicode checkmark */
    color: black;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 18px; /* Adjust size as needed */
    line-height: 1; /* Align checkmark vertically */
}

button {
    /*padding: 10px 20px;*/
    background-color: #00ffcc;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #00e6b8;
}

.selected-offer-container {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    padding: 20px;
    max-width: 800px !important;
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.3);
    color: #fff;
    margin: 110px auto;
}

.login-modal, .pricing-modal {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

/* Mobile styles */
@media (max-width: 600px) {
    .selected-offer-container {
        padding: 15px;
        margin-top: 200px;
    }

    .selected-offer-title {
        font-size: 20px;
    }

    .selected-offer-price {
        font-size: 18px;
    }

    .app-name-input {
        font-size: 14px;
        padding: 8px;
    }

    .llm-tag {
        font-size: 12px;
        padding: 4px 10px;
    }

    .deploy-button {
        font-size: 16px;
        padding: 8px;
    }

    .modal-content {
        max-width: 350px;
        background: linear-gradient(435deg, #333, #144);
    }

    .subscription-card {
        height: 200px;
        width: 300px;

    }

    .modal-price-content {
        width: 360px
    }

    .subscription-card h3 {
        font-size: 15px;
    }

}

/* Tablet styles */
@media (min-width: 601px) and (max-width: 1024px) {
    .selected-offer-container {
        padding: 18px;
        margin-top: 200px;
    }

    .selected-offer-title {
        font-size: 22px;
    }

    .selected-offer-price {
        font-size: 19px;
    }

    .app-name-input {
        font-size: 15px;
        padding: 9px;
    }

    .llm-tag {
        font-size: 13px;
        padding: 5px 12px;
    }

    .deploy-button {
        font-size: 17px;
        padding: 9px;
    }
}

.selected-offer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.selected-offer-title {
    font-size: 24px;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 255, 255, 1);
}

.selected-offer-price {
    font-size: 20px;
    color: #00ffcc;
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
}

.selected-offer-details {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.subscription-card.pro {
    position: relative;
    border: 2px solid #ffea00;
}

.subscription-tape {
    position: absolute;
    top: 15px;
    left: -30px;
    transform: rotate(-45deg);
    background: linear-gradient(45deg, #000000, #aaaaaa);
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 30px;
    text-align: center;
    font-size: 14px;
}
.splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1000;
}

.bubbles {
    position: relative;
    width: 100px;
    height: 100px;
}
.display-name {
    color: var(--neon-primary);
}

.bubble {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    animation: bubble 2s linear infinite;
}

.bubble:nth-child(1) { left: 10%; animation-delay: 0s; background-color: #ff7f50; }
.bubble:nth-child(2) { left: 20%; animation-delay: 0.2s; background-color: #6495ed; }
.bubble:nth-child(3) { left: 30%; animation-delay: 0.4s; background-color: #32cd32; }
.bubble:nth-child(4) { left: 40%; animation-delay: 0.6s; background-color: #ff1493; }
.bubble:nth-child(5) { left: 50%; animation-delay: 0.8s; background-color: #ffa500; }
.bubble:nth-child(6) { left: 60%; animation-delay: 1s; background-color: #00ced1; }
.bubble:nth-child(7) { left: 70%; animation-delay: 1.2s; background-color: #ff69b4; }
.bubble:nth-child(8) { left: 80%; animation-delay: 1.4s; background-color: #8a2be2; }
.bubble:nth-child(9) { left: 90%; animation-delay: 1.6s; background-color: #20b2aa; }
.bubble:nth-child(10) { left: 100%; animation-delay: 1.8s; background-color: #f0e68c; }

@keyframes bubble {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(500px);
        opacity: 0;
    }
}

.welcome-message {
    color: #ffffff;
    font-size: 24px;
    margin-top: 20px;
}

.login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    background: linear-gradient(435deg, #333, #144);
}

.input-container {
    margin-bottom: 15px;
}

.neon-button {
    background: #00e676;
    border: none;
    padding: 10px 20px;
    color: #fff;
    cursor: pointer;
    transition: 0.3s;
}

.neon-button:hover {
    background: #00c853;
}

.oauth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    gap: 20px;
}

.oauth-button {
    background: linear-gradient(435deg, #333, #144);
    border: 2px solid var(--neon-primary);
    color: var(--neon-primary);
    cursor: pointer;
    transition: 0.3s;
}

.oauth-button:hover {
    background: var(--neon-primary);
    color: #1a1a1a;
}

.logout-form-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    background: linear-gradient(435deg, #333, #144);
    color: white;
}
/* Container for the blogs */
.blogs-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 140px;
    padding-left: 110px;
    padding-right: 110px;
}

/* Styling for each blog tile */
.blog-tile {
    background: #000;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
    cursor: pointer;
    transition: transform 0.2s;
    display: flex;
    align-items: flex-start;
}

.blog-tile:hover {
    transform: scale(1.02);
}

/* Styling for the blog image */
.blog-image {
    flex: 0 0 150px;
    margin-right: 20px;
}

.blog-image img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
}

/* Styling for the blog content */
.blog-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

/* Styling for the blog title */
.blog-title {
    color: #00ffcc;
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
    font-size: 1.5em;
    margin-bottom: 10px;
    text-align: left;
    width: 100%;
}

.blog-paragraph {
    color: #fff;
    text-align: left;
    width: 100%;
}

/* Styling for the create new button */
.create-new-button {
    background-color: #00ffcc;
    color: #000;
    border: none;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    font-size: 30px;
    line-height: 60px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, transform 0.2s;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1000;
}

.create-new-button:hover {
    background-color: #000;
    color: #00ffcc;
    transform: scale(1.1);
}

.create-new-button::before {
    content: '+';
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .blog-tile {
        flex-direction: column;
        align-items: flex-start;
    }

    .blog-image {
        margin-right: 0;
        margin-bottom: 15px;
        width: 100%;
    }

    .blog-image img {
        width: 100%;
        height: 200px;
    }
    .blogs-container {
        padding-left: 5px;
        padding-right: 5px;
    }

}